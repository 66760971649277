import Vue from 'vue'
import VueRouter from 'vue-router'
import userService from '../services/user.service'
Vue.use(VueRouter)

const routes = [

  /*{
    path: '/',
    name: 'proximamente',
    component: () => import('../views/Proximamente'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },*/
  {
    path: '/',
    name: 'Inicio',
    component: () => import('../views/Home'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {
    path: '/planes',
    name: 'Planes',
    component: () => import('../views/Planes'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {
    path: '/pago',
    name: 'detalles de pago',
    component: () => import('../views/resumenPago.vue'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {
    path: '/pago-merch',
    name: 'detalles_de_pago_merch',
    component: () => import('../views/resumenPagoMerch.vue'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },

  {

    path: '/micuenta',
    name: 'Mi cuenta',
    component: () => import('../views/miCuenta.vue'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {

    path: '/clase-de-prueba',
    name: 'Clase de pureba',
    component: () => import('../views/clasePrueba.vue'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {

    path: '/calendario-piso',
    name: 'calendario piso',
    component: () => import('../views/CalendarioPiso.vue'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {
    path: '/calendario-piso-reagendar',
    name: 'calendario piso reagendar',
    component: () => import('../views/CalendarioPisoReagendar.vue'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },

  {

    path: '/calendario-pilates',
    name: 'calendario pilates',
    component: () => import('../views/CalendarioPilates.vue'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {

    path: '/calendario-pilates-reagendar',
    name: 'calendario pilates reagendar',
    component: () => import('../views/CalendarioPilatesReagendar.vue'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {

    path: '/calendario-mixto',
    name: 'calendario test mixto',
    component: () => import('../views/CalendarioMixto.vue'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  // {

  //   path: '/crear-cuenta',
  //   name: 'registro nuevo',
  //   component: () => import('../views/RegistroNuevo'),
  //   meta: {
  //     log: false,
  //     admin: false,

  //     auth: false,
  //   }
  // },
  {

    path: '/bienvenida',
    name: 'bienvenida',
    component: () => import('../views/Bienvenida'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {

    path: '/reglamento',
    name: 'reglamento',
    component: () => import('../views/reglamento'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {

    path: '/reglamento-oficial',
    name: 'reglamento oficial',
    component: () => import('../views/reglamentoSolitario'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {

    path: '/aviso-de-privacidad',
    name: 'Aviso de privacidad',
    component: () => import('../views/AvisoDePrivacidad'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {

    path: '/terminos-y-condiciones',
    name: 'Terminos y Condiciones',
    component: () => import('../views/TerminosYCond'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {

    path: '/cuestionario',
    name: 'cuestionario',
    component: () => import('../views/cuestionario'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {

    path: '/gracias',
    name: 'gracias',
    component: () => import('../views/Gracias'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {

    path: '/merch',
    name: 'merch',
    component: () => import('../components/merch/productos'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {

    path: '/merch-producto',
    name: 'merch-producto',
    component: () => import('../components/merch/producto'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
/**
   {

    path: '/registro',
    name: 'registro',
    component: () => import('../views/Registro'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
 */
  {
    path: '/iniciar-sesion',
    name: 'iniciarsesion',
    component: () => import('../views/IniciarSesion'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },

  {
    path: '/auth',
    name: 'autentificacion',
    component: () => import('../views/Autentificacion'),
    meta: {
      log: true,
      admin: true,

      auth: false
    }
  },

  {
    path: '/administrador',
    name: 'Dashboard',
    component: () => import('../views/Dashboard'),
    meta: {
      log: true,
      admin: true,

      auth: true,
    }
  },



  {
    path: '*',
    name: '404',
    component: () => import('../views/Error404'),
    meta: {
      log: false,
      admin: false,

      auth: false
    }
  },


]




const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Check if the route has a hash
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth' // Optional: Add smooth scrolling behavior
      }
    }
  }
})



router.beforeEach(async (to, from, next) => {

  let meta_admin = to.matched.some(record => record.meta.admin);

  let meta_log = to.matched.some(record => record.meta.log);
  let meta_auth = to.matched.some(record => record.meta.auth);

  let tokenexp = await userService.tokenexp()
  let check = false //comprobar si esta logeado
  let admin = false;

  let type = ''


  if (tokenexp) { // ver que el token no este expirado.
    localStorage.removeItem('tkn')
    localStorage.removeItem('ath') //limpiar el localstorage
    next()
  }


  let response = await userService.getData()

  if (response.status == 'error') {
    localStorage.removeItem('tkn')
    localStorage.removeItem('ath')
  }


  if (response.status == 'success') { //comprobar que sea un login valido.
    check = true;
    let isAdmin = userService.isAdmin(response.result);
    admin = isAdmin.admin;
    type = isAdmin.type;
  }

  let ath = await userService.getAth();


  // borrar el localstorage cada que vayamos a login.

  if (to.path === '/login' && check) {
    localStorage.removeItem('tkn')
    localStorage.removeItem('ath')

    next()
  }

  //redireccionamientos por rol.

  if (!meta_log) next();

  if (meta_log && !check) next('/login');

  if (meta_admin) {
    if (admin) {

      if (meta_auth) {
        if (ath) {
          next();
        } else {
          localStorage.removeItem('tkn')
          localStorage.removeItem('ath')
          next('/login')
        }
      } else { //...proximamente rutas que no requieran autentificacion
        next()
      }

    } else {
      localStorage.removeItem('tkn')
      localStorage.removeItem('ath')
      next('/')
    }
  } else {
    next()
  }


});




export default router

